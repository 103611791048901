<template>
  <div style=";margin: -15px -15px;background-color: #f0f2f5;">
    <!-- 日期查询框 -->
    <div class="datePicker">
      <div class="date">
        医生姓名/医院名称：<a-input  style="width: 180px" placeholder="请输入" v-model="searchName" @keyup.enter="searchBtn"></a-input>
      </div>
      <a-button type="primary" @click="searchBtn">查询</a-button>
      <a-button style="margin-left: 15px" @click="returnBtn">重置</a-button>
    </div>

    <!-- table -->
    <div class="table">
      <a-table :scroll="{ x: '1700px' }" :columns="tableColumns" :pagination="false" :data-source="tableData"
               :row-key="(record) => record.id">
        <span slot="count" slot-scope="text,record">
           <a @click="routerBtn(record)">{{record.count}}</a>
        </span>
        <span slot="tag" slot-scope="text,record">
           <a-tag v-for="(list,index) in record.labels" style="margin: 5px" v-show="list!==null" :key="index" color="#108ee9">
               {{list}}
           </a-tag>
        </span> 
        <span slot="latestTime" slot-scope="text,record">
          <span v-if="record.latestTime">{{ record.latestTime }}</span>
          <span v-if="!record.latestTime">最近一周未访问病例夹</span>
        </span> 
      </a-table>
      <!-- 分页 -->
      <div style="background-color: #fff;width:100%;height: 100px;">
        <a-pagination style="float: right;margin-top: 25px;" show-quick-jumper show-size-changer :page-size.sync="pagination.pageSize"
                      @change="pageChange" @showSizeChange="pageChange"  v-model="pagination.current" :total=pagination.total
        />
      </div>
    </div>








  </div>
</template>

<script>
import {delCase_tags, getCase_detail} from "@/service/caseClip_api";

export default {
  name: "caseClip_tags",
  data(){
    return{
      tableData: [],  //用于列表数据
      searchName: '' , //传输入框的数据
      pagination: {   //用于分页
        total: 0,
        current: 1,
        pageSize: 10,
      },
      tableColumns:[
        {
          title: '医生姓名',
          dataIndex: 'name',
          // scopedSlots: { customRender: 'titles' },
          width: '10%'
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
          width: '20%'
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
          width: '10%'
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
          width: '8%'
        },
        {
          title: '建立患者数量',
          align:'center',
          scopedSlots: { customRender: 'count' },
          width: '10%',
        },
        {
          title: '常用标签',
          scopedSlots: { customRender: 'tag' },
        },
        {
          title: '导出病程次数',
          align:'center',
          dataIndex: 'number',
          width: '10%',
        },
        {
          title: '附件所占空间',
          width: '10%',
          align:'center',

        },
        {
          title: '最近一次访问病历夹时间',
          dataIndex: 'latestTime',
          scopedSlots: { customRender: 'latestTime' },
        },
      ],
    }
  },
  mounted() {
    this.getTags()
  },
  methods:{
    async getTags(){
      const data={
        name:this.searchName,
        pageNum:this.pagination.current,
        pageSize: this.pagination.pageSize
      }
      const info =await getCase_detail(data)
      if (info.code===0){
        this.pagination.total=info.data.total;
        this.tableData=info.data.list.filter(value => value.hasOwnProperty('id'));
      }
    },
    returnBtn() {
      this.searchName=''
      this.pagination.current=1;
      this.getTags()
    },
    searchBtn(){
      this.pagination.current=1;
      this.getTags()
    },
    pageChange(page_no, page_size){
      this.pagination.current = page_no;
      this.pagination.pageSize = page_size;
      this.getTags()
    },
    routerBtn(row){
      this.$router.push({
        path: '/caseClipPatient',
        query: {
          id: row.id,
        }
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.datePicker{
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.date {
  /* margin: auto; */
  margin: 0 20px 0 10px;
  font-size: 15px;
  font-weight: 500;
  color:#272727;

}
.table{
  padding: 20px;
  background-color: #fff;
}

</style>
